<template>
    <AppPanel subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/matriz-config">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/matriz-config"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
                :saveData="saveData"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="matriz-config-form" />
                        <h3 class="ml-1">Configuração de matriz</h3>
                    </div>
                    <div class="field col-4">
                        <label for="nome">Nome da Matriz</label>
                        <InputText id="nome" v-model.trim="form.nome" autocomplete="off" :class="{ 'p-invalid': submitted && !form.nome }" />
                        <small class="p-error" v-if="submitted && !form.nome">Nome da Matriz é Obrigatório.</small>
                    </div>
                    <div class="field col-4">
                        <label for="nomeLinha">Nome da Linha</label>
                        <InputText
                            id="nomeLinha"
                            v-model.trim="form.nomeLinha"
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.nomeLinha }"
                        />
                        <small class="p-error" v-if="submitted && !form.nomeLinha">Nome da linha é Obrigatório.</small>
                    </div>
                    <div class="field col-4">
                        <label for="nomeColuna">Nome da Coluna</label>
                        <InputText
                            id="nomeColuna"
                            v-model.trim="form.nomeColuna"
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.nomeColuna }"
                        />
                        <small class="p-error" v-if="submitted && !form.nomeColuna">Nome da coluna é Obrigatório.</small>
                    </div>
                    <div class="field col-4">
                        <label for="nomeResultado">Nome da Resultado</label>
                        <InputText
                            id="nomeResultado"
                            v-model.trim="form.nomeResultado"
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.nomeResultado }"
                        />
                        <small class="p-error" v-if="submitted && !form.nomeResultado">Nome do resultado é obrigatório.</small>
                    </div>
                    <div class="field col-4">
                        <label for="matriz">Matriz</label>
                        <Dropdown
                            id="matriz"
                            class="dropdown-matriz"
                            v-model="valorTipoMatriz"
                            :options="tipoMatriz"
                            optionLabel="nome"
                            placeholder="Selecione uma matriz"
                            @change="alteraMatriz"
                            :class="{ 'p-invalid': submitted && !valorTipoMatriz }"
                            :disabled="verificaMatrizGerada"
                        />
                    </div>
                    <div class="field col-4 buttonGerarMatriz">
                        <Button label="Gerar Matriz" @click="gerarMatriz" :disabled="verificaMatrizGerada" />
                    </div>

                    <div class="flex justify-content-center align-items-center col-12">
                        <div v-if="valorTipoMatriz" class="">
                            <Card
                                ref="card"
                                :grupos="this.form.grupos"
                                @onChangeInputTitulo="onChangeInputTitulo"
                                @onChangeGrupo="onChangeGrupo"
                                @onChangeInputTituloEixo="onChangeInputTituloEixo"
                            />
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import Card from './components/Card.vue';
import { getClientBase } from '@/services/http';
import { flatten } from 'ramda';
import { showError } from '../../utils/Toast';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    data() {
        return {
            tipoMatriz: [
                { id: 1, nome: '4x4', quantLinhas: 4, quantColunas: 4 },
                { id: 2, nome: '5x5', quantLinhas: 5, quantColunas: 5 }
            ],
            form: {},
            valorTipoMatriz: null,
            submitted: false,
            service: null,
            matrizGerada: false
        };
    },
    components: {
    Card,
    AppInfoManual
},
    created() {
        this.service = new BaseService('/matriz-config');
    },
    computed: {
        verificaMatrizGerada() {
            return !!(this.form.id || this.$route.params.id);
        }
    },
    methods: {
        async gerarMatriz() {
            try {
                const { data } = await this.service.save(this.form);
                this.form.id = data.id;

                const responseMatriz = await getClientBase().post('/matriz-config/gerar-formatar-matriz', this.form);

                const retorno = this.formatarMatriz({
                    allLinhas: responseMatriz.data.allLinhas,
                    grupos: responseMatriz.data.grupos,
                    quantColunas: this.valorTipoMatriz.quantColunas
                });

                this.form.grupos = responseMatriz.data.grupos;
                this.form.matriz = retorno;
                this.$refs.card.loadData(this.form.matriz);
            } catch (error) {
                showError(this.$toast, error);
            }
        },

        formatarMatriz({ quantColunas, allLinhas, grupos }) {
            const linhasR = allLinhas.filter((p) => p.eixo == 'r');
            const linhasX = allLinhas.filter((p) => p.eixo == 'x');

            const linhas = [];

            let index = 0;
            let linhasRAdicionar = [];
            for (const linha of linhasR) {
                index++;
                linhasRAdicionar.push(linha);
                if (index == quantColunas) {
                    linhas.push({
                        colunas: linhasRAdicionar
                    });
                    index = 0;
                    linhasRAdicionar = [];
                }
            }

            linhas.push({
                eixoX: linhasX
            });

            const retorno = {
                eixoY: allLinhas.filter((p) => p.eixo == 'y'),
                grupos,
                linhas
            };

            return retorno;
        },
        onChangeInputTituloEixo(inputValue, idCard, eixo) {
            if (eixo === 'y') {
                for (const linha of this.form.matriz.eixoY) {
                    if (linha.id === idCard) {
                        return (linha.titulo = inputValue);
                    }
                }
            } else {
                for (const a in this.form.matriz.linhas) {
                    for (const b in this.form.matriz.linhas[a].eixoX) {
                        if (this.form.matriz.linhas[a].eixoX[b].id === idCard) {
                            this.form.matriz.linhas[a].eixoX[b].titulo = inputValue;
                        }
                    }
                }
            }
            this.$refs.card.loadData(this.form.matriz);
        },
        onChangeInputTitulo(idGrupo, inputValue) {
            for (const a in this.form.matriz.linhas) {
                for (const b in this.form.matriz.linhas[a].colunas) {
                    if (this.form.matriz.linhas[a].colunas[b].grupo.id === idGrupo) {
                        this.form.matriz.linhas[a].colunas[b].titulo = inputValue;
                    }
                }
            }
            this.$refs.card.loadData(this.form.matriz);
        },
        onChangeGrupo(idGrupo, idCard) {
            const novoGrupo = this.form.matriz.grupos.find((grupo) => grupo.id === idGrupo);
            let novoTitulo = null;
            for (const indexLinha in this.form.matriz.linhas) {
                for (const indexColuna in this.form.matriz.linhas[indexLinha].colunas) {
                    if (this.form.matriz.linhas[indexLinha].colunas[indexColuna].grupo.id === idGrupo) {
                        novoTitulo = this.form.matriz.linhas[indexLinha].colunas[indexColuna].titulo;
                    }
                }
                for (const indexColuna in this.form.matriz.linhas[indexLinha].colunas) {
                    if (this.form.matriz.linhas[indexLinha].colunas[indexColuna].id === idCard) {
                        this.form.matriz.linhas[indexLinha].colunas[indexColuna].grupo = novoGrupo;
                        this.form.matriz.linhas[indexLinha].colunas[indexColuna].titulo = novoTitulo ? novoTitulo : 'Informe o nível do Risco';
                    }
                }
            }
            this.$refs.card.loadData(this.form.matriz);
        },

        alteraMatriz(event) {
            this.valorTipoMatriz = event.value;
            this.form.quantLinhas = event.value.quantLinhas;
            this.form.quantColunas = event.value.quantColunas;
        },

        async saveData() {
            const linhas = flatten(
                this.form.matriz.linhas.map((p) => {
                    return p.colunas || p.eixoX;
                })
            );
            return {
                id: this.form.id,
                nomeMatriz: this.form.nome,
                nomeLinha: this.form.nomeLinha,
                nomeColuna: this.form.nomeColuna,
                nomeResultado: this.form.nomeResultado,

                linhas: [...this.form.matriz.eixoY, ...linhas]
            };
        },
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.valorTipoMatriz = this.tipoMatriz.find((p) => p.quantLinhas === data.matrizConfig.quantLinhas);
            this.form.nome = data.matrizConfig.nome;
            this.form.nomeLinha = data.matrizConfig.nomeLinha;
            this.form.nomeColuna = data.matrizConfig.nomeColuna;
            this.form.nomeResultado = data.matrizConfig.nomeResultado;
            this.form.id = data.matrizConfig.id;

            const retorno = await this.formatarMatriz({
                allLinhas: data.allLinhas,
                grupos: data.grupos,
                quantColunas: data.matrizConfig.quantColunas
            });

            this.form.grupos = retorno.grupos;
            this.form.matriz = retorno;
            this.$refs.card.loadData(this.form.matriz);
        },
        async onValidate(cbSuccess) {
            return cbSuccess();
        }
    }
};
</script>

<style>
.buttonGerarMatriz {
    justify-content: center;
    align-items: end;
}
</style>
